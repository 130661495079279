export function GetFullAddress({ address }) {
  const arr = []
  if (address) {
    if (address.street) arr.push(address.street)
    if (address.number) arr.push(`N ${address.number}`)
    if (address.neighborhood) arr.push(address.neighborhood)
    if (address.city)
      arr.push(`${address.city} - ${address.state}`)
  }
  return arr.join(', ')
}