import React from 'react'
import ClearIcon from '@material-ui/icons/Clear'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  emptyContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
    flex: 1,
    '& .MuiSvgIcon-root': {
      fontSize: '2.5rem'
    },
    '& h3': {
      marginBlockStart: '6px',
      marginBlockEnd: '4px',
    },
    '& p': {
      marginBlockStart: '0px',
      marginBlockEnd: '12px',
    }
  }
}))

export const PredictionsNotFound = ({ predictions = [], notFound = false }) => {
  const classes = useStyles()
  return (
    predictions.length === 0 && notFound &&
    <div className={classes.emptyContainer}>
      <ClearIcon color="primary" />
      <h3>Endereço não encontrado</h3>
      <p>O endereço procurado não foi encontrado, tente usar outros termos</p>
    </div>
  )
}
