const INITIAL_STATE = {
  
}

export const companyActions = {
  SET_COMPANY: '@COMPANY/SET_COMPANY'
}

export default function company(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@COMPANY/SET_COMPANY':
      return { ...state, ...action.payload }
    default:
      return state
  }
}