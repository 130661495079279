import React from 'react'
import MapIcon from '@material-ui/icons/Map'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  emptyContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
    flex: 1,
    '& .MuiSvgIcon-root': {
      fontSize: '2.5rem'
    },
    '& h3': {
      marginBlockStart: '6px',
      marginBlockEnd: '4px',
    },
    '& p': {
      marginBlockStart: '0px',
      marginBlockEnd: '12px',
    }
  }
}))

export const PredictionsEmpty = ({ predictions = [], notFound = false }) => {
  const classes = useStyles()
  return (
    predictions.length === 0 && !notFound &&
    <div className={classes.emptyContainer}>
      <MapIcon color="primary" />
      <h3>Pesquise seu endereço</h3>
      <p>Procure pelo nome da rua, CEP ou nome de um local</p>
    </div>
  )
}
