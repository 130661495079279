export const Constants = {
  googleKey: process.env.REACT_APP_GOOGLE_API,
  urlApi: process.env.REACT_APP_URL_API,
}

export const KeyStorage = {
  TOKEN: '@KURU/TOKEN',
  USER: '@KURU/USER'
}

export const FirebaseConfig = {
  apiKey: "AIzaSyC9Ukx5K7mDMQYdWfWYp7qSm2hX7h3JXiY",
  authDomain: "kuru-rsi.firebaseapp.com",
  databaseURL: "https://kuru-rsi.firebaseio.com",
  projectId: "kuru-rsi",
  storageBucket: "kuru-rsi.appspot.com",
  messagingSenderId: "56557043820",
  appId: "1:56557043820:web:a717257d48ba9c7069d849",
  measurementId: "G-8M4YCXX4L9"
}
