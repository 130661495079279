import React, { useState } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import statusOrder from '../../../Utils/statusOrder'
import * as _ from 'lodash'
import { format } from 'date-fns'
import { makeStyles } from '@material-ui/core/styles'
import { DialogViewOrder } from '../Dialogs/ViewOrder'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '10px',
    '&:last-child': {
      marginBottom: '2px'
    }
  },
  productItem: {
    '& .badge': {
      color: 'var(--primary)',
      border: '1px solid var(--primary)',
      borderRadius: '4px',
      padding: '0px 3px',
      marginRight: '5px'
    }
  }
}))

export const OrderItem = ({
  company = {},
  items = [],
  status,
  sequence,
  statusEvents = [],
  _id
}) => {
  const [openDetail, setOpenDetail] = useState(false)
  const classes = useStyles()

  function getDateOnStatus() {
    const statusAt = statusEvents.find(event => event.status === status) || {}
    try {
      return format(new Date(statusAt.date), 'dd/MM/yyyy HH:mm')
    } catch (error) {
      console.log('==> getDateOnStatus', error, statusAt.date)
      return ''
    }
  }

  return (
    <>
      <Card button className={classes.root} onClick={() => setOpenDetail(true)} >
        <CardHeader
          style={{ paddingBottom: '0px' }}
          avatar={<Avatar src={company?.brand?.logo} />}
          title={company?.name}
          subheader={`Pedido ${statusOrder[status]} - ${_.padStart(sequence, 4, '0')} - ${getDateOnStatus()}`}
          action={
            <IconButton aria-label="visualizar pedido" onClick={() => setOpenDetail(true)}>
              <ArrowForwardIcon />
            </IconButton>
          }
        />
        <CardContent>
          {items.map((product) => (
            <div className={classes.productItem} key={product._id}>
              <span className="badge">{product.quantity}</span>
              <span>{product.name}</span>
            </div>
          ))}
        </CardContent>
      </Card>
      <DialogViewOrder
        orderId={_id}
        open={openDetail}
        setOpen={setOpenDetail}
      />
    </>
  )

}