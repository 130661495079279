/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import Avatar from '@material-ui/core/Avatar'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import Container from '@material-ui/core/Container'
import Fab from '@material-ui/core/Fab'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import LockOpen from '@material-ui/icons/LockOpen'
import ExitToApp from '@material-ui/icons/ExitToApp'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MapIcon from '@material-ui/icons/Map'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import _ from 'lodash'

import { ApiClient } from '../../../Utils/Api'
import { useConfirm } from 'material-ui-confirm'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Input } from '../../../Components/Input'
import { ValidationForm } from '../../../Utils/ValidationForm'
import { FormatError } from '../../../Utils/FormatError'
import { toast } from 'react-toastify'
import { Spinner } from '../../../Components/Spinner'
import { userActions } from '../../../Store/reducer/user'
import { DialogAddress } from '../Dialogs/DialogAddress'
import { GetFullAddress } from '../../../Utils/GetFullAddress'

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    marginBottom: '80px',
    '& .MuiAvatar-root': {
      width: '110px',
      height: '110px'
    },
    '& .MuiListSubheader-root': {
      background: 'rgba(0,0,0,0.02)',
      width: 'calc(100% + 32px)',
      marginLeft: '-16px'
    }
  },
  pictureWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
    padding: '12px',
    paddingTop: '24px',
    background: 'var(--primary)',
    flexDirection: 'column',
    boxShadow: '1px 4px 8px rgb(2,2,2,0.2)',
    color: 'white',
    cursor: 'pointer',
    '& h3': {
      marginBlockStart: '6px',
      marginBlockEnd: '4px',
      textTransform: 'uppercase'
    },
    '& p': {
      marginBlockStart: '0px',
      marginBlockEnd: '12px',
    }
  },
  fabEditButton: {
    position: 'fixed',
    bottom: theme.spacing(12),
    right: theme.spacing(3),
    color: 'white'
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '& > button': {
      marginBottom: '12px'
    }
  },
}))
export const Profile = ({ changeNavigation }) => {
  const classes = useStyles()
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  const confirm = useConfirm()

  const [loading, setLoading] = useState(false)
  const [changeAvatar, setChangeAvatar] = useState()
  const [editMode, setEditMode] = useState(false)
  const [form, setForm] = useState({})
  const [anchorEl, setAnchorEl] = useState(null)
  const [showModalAddress, setShowModalAddress] = useState(false)

  const inptuFile = useRef(null)

  function handleToogleEditMode() {
    setEditMode(editMode !== true)
  }

  function handleCancel() {
    setEditMode(false)
    getMe()
  }

  const fields = [
    { field: 'name', label: 'Nome', type: 'text', required: true },
    { field: 'mail', label: 'E-mail', type: 'mail', required: true, editable: false },
    { field: 'document', label: 'CPF', type: 'text', required: false, mask: '999.999.999-99' },
    { field: 'phone', label: 'Celular', type: 'text', required: false, mask: '(99) 99999-9999' },
  ]

  useEffect(() => {
    if (user.isLogged) getMe()
  }, [user.isLogged])

  useEffect(() => {
    if (changeAvatar && form.photo) {
      save()
    }
  }, [changeAvatar, form.photo])

  async function getMe() {
    setLoading(true)
    try {
      const { data } = await ApiClient.get('/customer/profile/me')
      dispatch({ type: userActions.SET_ADDRESS, payload: _.get(data, 'address', []) })
      setForm(data)
    } finally {
      setLoading(false)
    }
  }

  async function save() {
    setLoading(true)
    try {
      const { erros, isValid } = ValidationForm({ form, fields })
      if (!isValid) {
        toast.dismiss()
        toast.warn(erros.join('\n'), { autoClose: false, closeButton: true })
      }

      const sender = _.pick(form, 'name', 'phone', 'document', 'photo', 'address')
      const { data } = await ApiClient.put('/customer/profile/me', sender)
      toast.success('Perfil atualizado com sucesso!', { autoClose: 3000 })

      setEditMode(false)
      setChangeAvatar(false)
      if (data.photo) dispatch({ type: userActions.SET_AVATAR, payload: data.photo })

    } catch (error) {
      const errors = FormatError({ error })
      toast.warn(errors.join('\n'))
    } finally {
      setLoading(false)
    }
  }

  function handleChoiceAvatar(event) {
    setAnchorEl(event.currentTarget)
  };

  function clearAvatar() {
    dispatch({ type: userActions.SET_AVATAR, payload: null })
    setAnchorEl(null)
  }

  function selectAvatar() {
    inptuFile.current.click()
  }

  function onSelectAvatarChange(event) {
    const files = event.target.files
    const file = files[0]
    let extension = null

    function handleReaderLoaded(event) {
      const binaryString = event.target.result
      const base64textString = btoa(binaryString)
      setForm({ ...form, photo: `data:image/${extension};base64,${base64textString}` })
      setChangeAvatar(true)
      setAnchorEl(null)
    }

    if (files && file) {
      const ext = file.name.split('.')
      extension = ext[1]
      const reader = new FileReader()
      reader.onload = handleReaderLoaded.bind(this)
      reader.readAsBinaryString(file)
    }
  }

  function handleLogout() {
    dispatch({ type: userActions.LOGOUT })
    changeNavigation('Login')
  }

  function handleCloseAddressModal(data) {
    if (data && data.street) {
      const address = _.get(form, 'address', [])
      address.push(data)
      setForm({ ...form, address })
    }
    setShowModalAddress(false)
  }

  function handleRemoveAddress(address, index) {
    confirm({
      cancellationText: 'Cancelar',
      confirmationText: 'Sim',
      title: 'Excluir endereço',
      description: `Deseja realmente excluir o endereço ${address.street}?`
    }).then(() => {
      const address = _.get(form, 'address', [])
      address.splice(index)
      setForm({ ...form, address })
    })
  }

  return (
    <div className={classes.root}>
      <div className={classes.pictureWrapper}>
        <Avatar src={form.photo} onClick={handleChoiceAvatar} />
        <input style={{ display: 'none' }} ref={inptuFile} type="file" accept="image/x-png,image/jpeg, image/jpg" onChange={onSelectAvatarChange} />
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem onClick={selectAvatar}>Selecionar Foto</MenuItem>
          <MenuItem onClick={clearAvatar}>Limpar Foto</MenuItem>
        </Menu>

        <h3>{form.name}</h3>
        <p>{form.mail}</p>
      </div>
      <Container maxWidth="sm">
        <List style={editMode ? { marginTop: '20px' } : {}} >
          {fields.map((element) =>
            <ListItem key={element.field}>
              <Input {...element} setForm={setForm} form={form} inputMode={editMode} />
            </ListItem>
          )}
          <ListSubheader>
            Endereço
          </ListSubheader>

          {form.address && form.address.map((address, index) =>
            <ListItem key={address._id}>
              <MapIcon style={{ marginRight: '12px' }} />
              <ListItemText primary={GetFullAddress({ address })} />
              {
                editMode &&
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveAddress(address, index)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              }
            </ListItem>
          )}

          {_.size(form.address) === 0 &&
            <ListItem>
              <ListItemText secondary="Você ainda não cadastrou nenhum endereço" />
            </ListItem>
          }

          {!editMode &&
            <>
              <ListSubheader>
                Ações
              </ListSubheader>
              <ListItem button onClick={() => changeNavigation('ChangePassword')}>
                <ListItemIcon>
                  <LockOpen />
                </ListItemIcon>
                <ListItemText primary="Alterar Senha" />
              </ListItem>
              <ListItem button onClick={handleLogout}>
                <ListItemIcon>
                  <ExitToApp />
                </ListItemIcon>
                <ListItemText primary="Sair" />
              </ListItem>
            </>
          }

        </List>
        <div className={classes.buttonsContainer}>
          {editMode && !loading &&
            <>
              <Button fullWidth variant="outlined" color="secondary" startIcon={<MapIcon />} onClick={() => setShowModalAddress(true)}>Novo endereço</Button>
              <Button fullWidth variant="contained" color="primary" startIcon={<SaveIcon />} onClick={save}>Salvar</Button>
              <Button fullWidth size="small" color="primary" onClick={handleCancel}>Cancelar</Button>
            </>
          }
          {editMode && loading && <Spinner />}
          {!editMode && !loading &&
            <Fab color="primary" aria-label="Editar" onClick={handleToogleEditMode} className={classes.fabEditButton}>
              <EditIcon />
            </Fab>
          }
        </div>
      </Container>
      <DialogAddress
        visible={showModalAddress}
        dismiss={handleCloseAddressModal}
      />
    </div>

  )
}