export function DayOfWeekParse(day) {
  try {
    const dayOfWeek = {
      '0': 'Domingo',
      '1': 'Segunda-Feira',
      '2': 'Terça-Feira',
      '3': 'Quarta-Feira',
      '4': 'Quinta-Feira',
      '5': 'Sexta-Feira',
      '6': 'Sábado'
    }
    return dayOfWeek[day]
  } catch (error) {
    return ''
  }
}