/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import classnames from 'classnames'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { Quantity } from '../../../Components/Quantity'
import { makeStyles } from '@material-ui/core/styles'
import { formatValue } from '../../../Utils/FormatValue'
import { DialogProduct } from '../Dialogs/DialogProduct'
import { DialogProductComplements } from '../Dialogs/DialogProductComplements'

import styles from '../style'
import FirebaseService from '../../../Utils/FirebaseService'
const useStyles = makeStyles(styles)

export const ProductItem = ({ product, onChangeQuantity, companyIsOpen }) => {
  const classes = useStyles()
  const [previewVisivle, setPreviewVisible] = useState(false)
  const [addOns, setAddOns] = useState(false)
  const [quantity, setQuantity] = useState(0)
  const { items = [] } = useSelector(state => state.checkout)

  function handleShowOrHiddenPreviewProduct(state) {
    setPreviewVisible(state)
    FirebaseService.registerEvent({ name: 'action:productItem:preview', params: { state } })
  }

  function handleShowOrHiddenAddOns(state) {
    setAddOns(state)
    FirebaseService.registerEvent({ name: 'action:productItem:preview-add-ons', params: { state } })
  }

  useEffect(() => {
    const QUANTITY = getQuantityOnCheckout({ product })
    setQuantity(QUANTITY)
    FirebaseService.registerEvent({ name: 'action:productItem:change-quantity', params: { product: product._id, quantity: QUANTITY } })
  }, [items])

  function getQuantityOnCheckout({ product }) {
    try {
      const quanity = items.find(prod => prod._id === product._id).quantity
      return quanity
    } catch (error) {
      return 0
    }
  }

  const isAvailableCheckoutProduct = (companyIsOpen && _.get(product, 'isAvailable', true))
  const showAddButton = (_.size(product.complements) === 0 && isAvailableCheckoutProduct)
  const showComplementsButton = (_.size(product.complements) > 0 && isAvailableCheckoutProduct)

  const productItemClassName = classnames(
    classes.product_item_container,
    { [classes.product_not_available_item]: !_.get(product, 'isAvailable', true) }
  )

  return (
    <React.Fragment>
      <div key={product._id} className={productItemClassName}>
        {product.photo && <Avatar src={product.photo} style={{ cursor: 'pointer' }} onClick={() => handleShowOrHiddenPreviewProduct(true)} />}
        <p style={{ cursor: 'pointer' }} onClick={() => handleShowOrHiddenPreviewProduct(true)}>{product.name}
          <span>{formatValue(product.price, 'money')}</span>
        </p>
        <div>
          {showAddButton &&
            <Quantity
              product={product}
              quantity={quantity}
              onChange={quantity => onChangeQuantity({ quantity, product })} />
          }
          {showComplementsButton &&
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              onClick={() => handleShowOrHiddenAddOns(true)}
            > Adicionais </Button>
          }
          {!_.get(product, 'isAvailable', true) &&
            <div className={classes.product_not_available_badge}>
              Não disponivel
            </div>
          }
        </div>
      </div>
      <DialogProduct
        product={product}
        visible={previewVisivle}
        dismiss={() => handleShowOrHiddenPreviewProduct(false)}
      />
      <DialogProductComplements
        product={product}
        visible={addOns}
        dismiss={() => handleShowOrHiddenAddOns(false)}
      />
    </React.Fragment>
  )
}