import { createStore, combineReducers } from 'redux'

import checkout from './reducer/checkout'
import company from './reducer/company'
import products from './reducer/products'
import user from './reducer/user'

const reducers = combineReducers({
  company,
  checkout,
  products,
  user
})

const store = createStore(reducers)

export default store