/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'

import { Input } from '../../../../Components/Input'

export const EditAddress = ({ address, onChange }) => {
  const [form, setForm] = useState(address)
  
  useEffect(() => {
    onChange && onChange(form)
  }, [form])

  const fields = [
    { field: 'zipCode', label: 'CEP', type: 'text', required: true, mask: '99999-999' },
    { field: 'street', label: 'Endereço', type: 'text', required: true },
    { field: 'number', label: 'Número', type: 'mail', required: true },
    { field: 'neighborhood', label: 'Bairro', type: 'text', editable: false },
    { field: 'complement', label: 'Complemento', type: 'text' },
    { field: 'city', label: 'Cidade', type: 'text', editable: false },
    { field: 'state', label: 'Estado', type: 'text', editable: false },
  ]

  return (
    address &&
    <List>
      {fields.map((element, index) =>
        <ListItem key={`${index}_${element.field}`}>
          {Input({ ...element, setForm, form, inputMode: true })}
        </ListItem>
      )}
    </List>
  )
}