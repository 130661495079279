import _ from 'lodash'

export const productsActions = {
  SET_PRODUCTS: '@PRODUCTS/SET_PRODUCTS'
}

const INITIAL_STATE = {
  data: [],
  categories: []
}

function mapCategories(products) {
  const map = products.map(product => ({
    _id: product.category._id,
    name: product.category.name,
    position: product.category.position,
  }))
  const categoriesOptions = _.sortBy(
    _.uniqBy(map, uniq => uniq._id)
    , sort => sort.position
  )

  categoriesOptions.forEach(category => {
    category['products'] = products.filter(
      product => product.category._id === category._id
    )
  })
  return categoriesOptions
}

export default function products(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case '@PRODUCTS/SET_PRODUCTS': {
      const data = payload
      const categories = mapCategories(data)
      return { ...state, data, categories }
    }
    default:
      return state
  }
}