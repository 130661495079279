import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import { RegisterComponent } from '../../../../../Components/Register'

const useStyles = makeStyles((theme) => ({
  root: {

  }
}))

export const DialogRegister = ({
  open = false,
  onClose,
  onRegisterSuccess
}) => {
  const classes = useStyles()
  return (
    <Dialog
      className={classes.root}
      open={open}
      onClose={onClose}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle>Cadastrar</DialogTitle>
      <DialogContent>
        <RegisterComponent
          onSuccessRegister={onRegisterSuccess}
        />
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={onClose} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
