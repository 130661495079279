import _ from 'lodash'

const INITIAL_STATE = {
  amount: 0,
  subTotal: 0,
  deliveryFee: 0.0,
  discountCoupon: 0,
  count: 0,
  items: [],
  address: {},
  withdraw: false,
  paymentMethod: undefined,
  needChange: false,
  changeAmount: 0
}

export const checkoutActions = {
  ADD_PRODUCT: '@CHECKOUT/ADD_PRODUCT',
  CHANGE_COUPON: '@CHECKOUT/CHANGE_COUPON',
  WITHDRAW: '@CHECKOUT/WITHDRAW',
  SELECT_ADDRESS: '@CHECKOUT/SELECT_ADDRESS',
  FINISHED_ORDER: '@CHECKOUT/FINISHED_ORDER',
  CHANGE_PAYMENT_METHOD: '@CHECKOUT/CHANGE_PAYMENT_METHOD',
}

function calculateAmount({ items, deliveryFee, discountCoupon }) {
  try {
    return calculateSubTotal({ items }) + deliveryFee + discountCoupon
  } catch (error) {
    return 0
  }
}

function calculateSubTotal({ items }) {
  try {
    return (items || []).reduce((acc, product) => acc + (product.price * product.quantity), 0)
  } catch (error) {
    return 0
  }
}

function count({ items }) {
  try {
    return (items || []).length
  } catch (error) {
    return 0
  }
}

function mapProduct({ name, _id, complements = [], price }) {
  return ({
    name,
    _id,
    complements,
    price
  })
}

function changeQuantityProduct({ product, quantity }) {
  return (state) => {
    const isHasComplements = _.get(product, 'complements', []).length > 0
    if (isHasComplements) {
      state.items.push({
        ...mapProduct(product),
        quantity
      })
    } else {
      const index = _.get(state, 'items', []).findIndex(p => p._id === product._id)
      if (index === -1)
        state.items.push({
          ...mapProduct(product),
          quantity
        })
      else
        state.items[index].quantity = quantity
    }
    return state.items.filter(product => !!product.quantity)
  }
}

export default function checkout(state = _.cloneDeep(INITIAL_STATE), { type, payload }) {
  switch (type) {
    case checkoutActions.ADD_PRODUCT: {
      const { product, quantity } = payload
      state.items = changeQuantityProduct({ product, quantity })(state)
      state.amount = calculateAmount(state)
      state.subTotal = calculateSubTotal(state)
      state.count = count(state)
      return { ...state }
    }
    case checkoutActions.CHANGE_COUPON: {
      return { ...state }
    }
    case checkoutActions.WITHDRAW: {
      state.deliveryFee = 0
      state.withdraw = true
      state.address = {}
      state.amount = calculateAmount(state)
      state.subTotal = calculateSubTotal(state)
      return { ...state }
    }
    case checkoutActions.SELECT_ADDRESS: {
      state.deliveryFee = payload.deliveryFee
      state.address = payload.address
      state.withdraw = false
      state.amount = calculateAmount(state)
      state.subTotal = calculateSubTotal(state)
      return { ...state }
    }
    case checkoutActions.FINISHED_ORDER: {
      return { ...INITIAL_STATE }
    }
    case checkoutActions.CHANGE_PAYMENT_METHOD: {
      state.paymentMethod = payload.paymentMethod
      state.needChange = payload.needChange
      state.changeAmount = payload.changeAmount
      return { ...state }
    }
    default:
      return state
  }
}