/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { useSelector } from 'react-redux'
import { OrderList } from './Blocks/OrderList'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Container from '@material-ui/core/Container'

export const OrderHistoryPage = () => {
  const user = useSelector(state => state.user)
  return (
    <Container style={{
      marginBottom: '90px',
      paddingTop: '16px'
    }}>
      { user.isLogged && <OrderList />}
      { !user.isLogged &&
        <ListItem>
          <ListItemText style={{ color: 'red' }} primary="Efetue o login para listar seus pedidos" />
        </ListItem>
      }
    </Container>
  )

}