import List from '@material-ui/core/List'
import PinDropIcon from '@material-ui/icons/PinDrop'
import { ListItem, ListItemText } from '@material-ui/core'

export const PredictionsList = ({ predictions, getDetails }) => (
  predictions.length > 0 &&
  <List>
    {predictions.map(({ place_id, main_text, secondary_text }) =>
      <ListItem button key={place_id} onClick={() => getDetails(place_id)}>
        <PinDropIcon style={{ marginRight: '12px' }} />
        <ListItemText primary={main_text} secondary={secondary_text}></ListItemText>
      </ListItem>
    )}
  </List>
)