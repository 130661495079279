/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { formatValue } from '../../../Utils/FormatValue'
import { useDispatch } from 'react-redux'
import { checkoutActions } from '../../../Store/reducer/checkout'
import { ListComplements } from '../Blocks/ListComplements'
import { toast } from 'react-toastify'

import Grid from '@material-ui/core/Grid'

import _ from 'lodash'
import FirebaseService from '../../../Utils/FirebaseService'

const useStyles = makeStyles((theme) => ({
  root: {
    '& img': {
      width: 'calc(100% + 48px)',
      height: 'auto',
      'object-fit': 'cover',
      marginLeft: '-24px',
      boxShadow: '1px 7px 10px rgb(2,2,2,0.4)',
      marginBottom: '16px',
    },
    '& span.price-value': {
      color: 'red',
      fontSize: '1.6rem',
      fontWeight: 'bold'
    },
    '& div.price-container': {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    '& .MuiDialogContent-root': {
      paddingTop: '0px'
    }
  },
  productDescription: {
    whiteSpace: 'pre-wrap'
  }
}))


export const DialogProductComplements = ({ visible = false, dismiss, product }) => {
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles()
  const [finalPrice, setFinalPrice] = useState(product.price)
  const dispatch = useDispatch()

  useEffect(() => {
    setOpen(visible)
    if (visible) {
      removeAllQuantitys()
      FirebaseService.pageView('DialogProductComplements')
    }
  }, [visible])

  function handleClose() {
    setOpen(false)
    dismiss(false)
  }

  function handleUpdatePrice(addPrice) {
    product.priceCalculated = product.price + addPrice
    setFinalPrice(product.priceCalculated)
  }

  function handleConfirm() {
    const erros = []
    toast.dismiss()

    for (const complement of product.complements) {
      const quantitySelected = complement.items.reduce((acc, item) => acc + (item.quantity || 0), 0)
      const max = complement.max || 0
      const min = complement.min || 0
      if (quantitySelected < min || quantitySelected > max) {
        erros.push(`O Adicional ${complement.name} está inválido, reveja as quantidades selecioandas`)
      }
    }

    if (erros.length > 0) {
      toast.warn(erros.join('\n'), {
        autoClose: false,
        closeButton: true
      })
      return
    }

    const _product = _.cloneDeep(product)
    _product.price = product.priceCalculated || product.price
    _product.complements = _.flatten(
      product.complements.map(({ name, _id, items }) => ({
        _id,
        name,
        items: items
          .filter(item => !!item.quantity)
          .map(({ name, price, quantity, _id }) =>
            ({ name, price, quantity, _id })
          )
      }))
    )
    dispatch({ type: checkoutActions.ADD_PRODUCT, payload: { quantity: 1, product: _product } })
    removeAllQuantitys()
    dismiss(false)
    toast.success(`${product.name} adicionado ao carrinho`)
  }

  function removeAllQuantitys() {
    product.complements = product.complements.map(complement => ({
      ...complement,
      items: complement.items.map(item => ({ ...item, quantity: 0 }))
    }))
    setFinalPrice(product.price)
  }

  return (
    <Dialog
      className={classes.root}
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle>Adicionais</DialogTitle>
      <DialogContent>
        {product.photo && <img alt={product.name} src={product.photo} />}
        <DialogContentText className={classes.productDescription}>
          <h3>{product.name}</h3>
          {product.description}
        </DialogContentText>
        <div className='price-container'>
          <span className='price-value'>
            {formatValue(product.price, 'money')}
          </span>
        </div>
        <ListComplements
          {...product}
          onUpdatePrice={handleUpdatePrice}
        />
      </DialogContent>
      <DialogActions>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center">
          <Button size="small" onClick={handleClose} color="secondary" variant="outlined">Cancelar</Button>
          Valor: {formatValue(finalPrice, 'money')}
          <Button size="small" onClick={handleConfirm} color="primary" variant="contained">
            Confirmar
          </Button>
        </Grid>
      </DialogActions>
    </Dialog >
  )


}