import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { checkoutActions } from '../../../Store/reducer/checkout'
import { ProductItem } from './ProductItem'

import styles from '../style'
const useStyles = makeStyles(styles)

export const Categories = () => {
  const classes = useStyles()
  const { categories } = useSelector(state => state.products)
  const company = useSelector(state => state.company)
  const dispatch = useDispatch()

  function onChangeQuantity({ quantity, product }) {
    dispatch({ type: checkoutActions.ADD_PRODUCT, payload: { quantity, product } })
  }

  return (
    <div>
      {
        categories.map((category, index) =>
          <Card key={category._id} className={classes.card_container}>
            <CardHeader
              style={{ padding: '6px', color: 'var(--primary)' }}
              title={category.name}
            />
            <CardContent style={{ padding: '4px' }}>
              {category.products.map(product =>
                <ProductItem
                  product={product}
                  key={product._id}
                  onChangeQuantity={onChangeQuantity}
                  companyIsOpen={company.isOpen}
                />
              )}
            </CardContent>
          </Card>
        )
      }
    </div>
  )
}