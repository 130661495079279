import _ from 'lodash'
import FirebaseService from './FirebaseService'

export function FormatError({ error }) {
  const errors = []
  try {
    const status = _.get(error, 'response.status')
    const errorAxios = _.get(error, 'response.data.error') || _.get(error, 'response.data.errors[0]') || 'Não conseguimos processar sua solicitação'
    if (status === 422) {
      errors.push(errorAxios)
    }
    if (status === 404) errors.push(_.get(error, 'response.data.message', 'Erro desconhecido'))
    if (status === 500) FirebaseService.registerError({ description: `Erro interno do servidor, motivo: - - `, isFatal: true })
    return errors
  } catch (error) {
    FirebaseService.registerError({ description: 'Erro desconhecido', isFatal: true })
    return ['Erro desconhecido']
  }
}