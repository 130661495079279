/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'

import { ApiClient } from '../../Utils/Api'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Input } from '../Input'
import { ValidationForm } from '../../Utils/ValidationForm'
import { FormatError } from '../../Utils/FormatError'
import { toast } from 'react-toastify'
import { Spinner } from '../Spinner'
import { userActions } from '../../Store/reducer/user'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    marginBottom: '50px',
    alignItems: 'center',
    padding: '50px 0px',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    '& .MuiList-root': {
      width: '90%'
    },
    '& .MuiAvatar-root': {
      width: '120px',
      height: '120px',
      marginBottom: '16px'
    }
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '90%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '& > button': {
      marginBottom: '12px'
    }
  },
  termsContainer: {
    width: '90%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '& a': {
      color: 'var(--primary)',
      fontWeight: '500'
    }
  }
}))

export const RegisterComponent = ({
  onSuccessRegister,
  onCancelRegister,
  showLogo = true,
  showTerms = true,
  autoLogin = true
}) => {
  const classes = useStyles()
  const company = useSelector(state => state.company)
  const [form, setForm] = useState({})
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const fields = [
    { field: 'name', label: 'Nome', type: 'text', required: true },
    { field: 'mail', label: 'E-mail', type: 'mail', required: true },
    { field: 'phone', label: 'Celular', type: 'text', required: false },
    { field: 'password', label: 'Senha', type: 'password', required: true },
    { field: 'passwordConfirm', label: 'Confirmação de Senha', type: 'password', required: true },
  ]

  async function handleSave() {
    setLoading(true)
    try {
      const { erros, isValid } = ValidationForm({ form, fields })

      if (!isValid) {
        toast.dismiss()
        toast.warn(erros.join('\n'), { autoClose: false, closeButton: true })
      }

      await ApiClient.post('/public/customer/register', form)
      toast.success('Cadastro efetuado com sucesso!')
      onSuccessRegister()

      if (autoLogin)
        login()

    } catch (error) {
      const errors = FormatError({ error })
      toast.warn(errors.join('\n'))
    }
    finally {
      setLoading(false)
    }
  }

  async function login() {
    try {
      const { mail, password } = form
      const { data } = await ApiClient.post('/public/customer/login', { mail, password })
      dispatch({ type: userActions.LOGIN, payload: data })
    } finally {

    }
  }

  return (
    <div className={classes.root}>
      {showLogo && <Avatar src={company.brand.logo} />}
      <List fullWidth>
        {fields.map((element) =>
          <ListItem key={element.field}>
            <Input {...element} setForm={setForm} form={form} inputMode={true} />
          </ListItem>
        )}
      </List>
      {showTerms &&
        <div className={classes.termsContainer}>
          Ao se cadastarar você estará concordando com os <a href="#" target="_blank">Termos de Serviço</a> e a <a href="#" target="_blank">Política de privacidade</a> da Plataforma Kuru
        </div>
      }
      <div className={classes.buttonsContainer}>
        {loading && <Spinner />}
        {!loading && (
          <>
            <Button fullWidth variant="contained" color="primary" onClick={handleSave}>Cadastrar</Button>
            {onCancelRegister && <Button fullWidth size="small" color="primary" onClick={onCancelRegister} >Voltar ao login</Button>}
          </>
        )}
      </div>
    </div>
  )
}