import React, { useState } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Button from '@material-ui/core/Button'

import { ApiClient } from '../../Utils/Api'
import { makeStyles } from '@material-ui/core/styles'
import { Input } from '../Input'
import { ValidationForm } from '../../Utils/ValidationForm'
import { FormatError } from '../../Utils/FormatError'
import { toast } from 'react-toastify'
import { Spinner } from '../Spinner'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    '& .MuiList-root': {
      width: '90%'
    },
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '90%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '& > button': {
      marginBottom: '12px'
    }
  },
}))

export const ForgotPasswordComponent = ({
  changeNavigation,
  onSuccess,
  onCancel
}) => {
  const classes = useStyles()

  const [form, setForm] = useState({})
  const [loading, setLoading] = useState(false)

  const fields = [
    { field: 'mail', label: 'E-mail', type: 'mail', required: true }
  ]

  async function handleSave() {
    setLoading(true)
    try {
      const { erros, isValid } = ValidationForm({ form, fields })
      if (!isValid) {
        toast.dismiss()
        toast.warn(erros.join('\n'), { autoClose: false, closeButton: true })
      }
      const { data } = await ApiClient.post('/public/customer/forgot-password', form)
      toast.success(data.message)
      onSuccess()
    } catch (error) {
      const errors = FormatError({ error })
      toast.warn(errors.join('\n'))
    }
    finally {
      setLoading(false)
    }
  }

  return (
    <div className={classes.root}>
      <List fullWidth>
        {fields.map(element =>
          <ListItem>
            <Input {...element} setForm={setForm} form={form} inputMode={true} />
          </ListItem>
        )}
      </List>
      <div className={classes.buttonsContainer}>
        {loading && <Spinner />}
        {!loading && (
          <>
            <Button fullWidth variant="contained" color="primary" onClick={handleSave}>Recuperar senha</Button>
            {onCancel && <Button fullWidth size="small" color="primary" onClick={onCancel} >Voltar ao login</Button>}
          </>
        )}
      </div>
    </div>
  )

}