const homeStyle = (theme) => ({
  cover: {
    width: '100%',
    height: '280px',
    objectFit: 'cover',
    marginBottom: '3px',
    boxShadow: '-5px 6px 20px rgb(0,0,0,0.4)'
  },
  avatar_large: {
    width: '120px',
    height: '120px',
    position: 'absolute',
    top: '16px',
    left: '50%',
    marginLeft: '-70px',
    border: '4px solid white',
    boxShadow: '4px 4px 10px rgba(0,0,0, 0.4)'
  },
  social_container: {
    top: '10px',
    right: '10px',
    margin: '4px'
  },
  social_icon: {
    color: 'white',
    fontSize: '2rem',
    marginRight: '10px',
    cursor: 'pointer',
    '&:hover': {
      color: 'var(--primary)'
    },
    '&:last-child': {
      marginRight: '0px'
    }
  },
  info_company_container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    padding: '0 10px',
    top: '130px',
    textAlign: 'center',
    '& h2': {
      color: 'white',
      'margin-block-start': '0.60em',
      'margin-block-end': '0.20em',
    },
    '& span': {
      color: 'white',
      fontSize: '0.8rem',
      marginLeft: '8px'
    }
  },
  status: {
    padding: '2px 8px',
    borderRadius: '10px',
  },
  open: {
    background: 'green',
  },
  closed: {
    background: 'red',
  },
  spacing: {
    margin: theme.spacing(0.5),
  },
  card_container: {
    marginBottom: '12px',
    '&:first-child': {
      marginTop: '12px'
    },
    '&:last-child': {
      marginBotton: '12px'
    }
  },
  product_item_container: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px',
    '& p': {
      margin: '0px 10px',
      flex: 1,
      '& span': {
        color: 'red',
        fontWeight: 'bold',
        display: 'block'
      }
    }
  },
  product_item: {

  },
  product_not_available_badge: {
    background: '#ccc',
    padding: '4px 10px',
    borderRadius: '6px'
  },
  product_not_available_item: {
    opacity: '0.7',
    filter: 'grayscale(1)'
  }
})
export default homeStyle