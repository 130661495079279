const AddressMapKey = {
  'administrative_area_level_1': 'state',
  'administrative_area_level_2': 'city',
  'route': 'street',
  'sublocality_level_1': 'neighborhood',
  'postal_code': 'zipCode',
  'street_number': 'number',
}

function switchLongOrShortName(item) {
  const type = item.types[0]
  if (AddressMapKey[type] === 'state')
    return item.short_name
  else
    return item.long_name
}

export const DetailAddressParse = (place) => {
  const mapped = place.address_components.map(item => ({ [item.types[0]]: switchLongOrShortName(item) }))
  const placeDetail = {}
  for (const item of mapped) {
    const key = Object.keys(item)[0]
    placeDetail[AddressMapKey[key] || key] = item[key]
  }
  placeDetail['lat'] = place.geometry.location.lat()
  placeDetail['lng'] = place.geometry.location.lng()

  delete placeDetail['country']

  return placeDetail
}