import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import Badge from '@material-ui/core/Badge'
import { formatValue } from '../../../Utils/FormatValue'
import { DialogCheckout } from '../Dialogs/DialogCheckout'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    background: '#f2f2f2',
    bottom: '73px',
    position: 'fixed',
    left: '0px',
    boxShadow: '-1px -10px 12px rgb(140,137,137, 0.40)'
  },
  price_container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 12px',
    marginLeft: '12px',
    cursor: 'pointer'
  }
}))

export const CheckoutSummary = () => {
  const checkout = useSelector(state => state.checkout)
  const classes = useStyles()
  const [viewDialogCheckout, setViewDialogCheckout] = useState()
  return (
    <>
      {
      checkout.items.length > 0 &&
        <Card className={classes.root}>
          <Grid item xs={7} className={classes.price_container} onClick={() => setViewDialogCheckout(true)}>
            <Badge
              badgeContent={checkout.count}
              color="error">
              <ShoppingCartIcon color="secondary" />
            </Badge>
            <h4>{formatValue(checkout.amount, 'money')}</h4>
          </Grid>
          <Grid xs={4} container direction="row" justify="flex-end">
            <Button onClick={() => setViewDialogCheckout(true)} size="small" variant="contained" color="secondary">
              Continuar
            </Button>
          </Grid>
          <DialogCheckout
            open={viewDialogCheckout}
            setOpen={setViewDialogCheckout}
          />
        </Card>
      }
    </>
  )
}