import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { Constants, KeyStorage } from './Constants'

axios.defaults.baseURL = Constants.urlApi

const token = localStorage.getItem(KeyStorage.TOKEN)

function tokenIsExpired(token) {
  const { exp } = jwt_decode(token, { complete: true })
  return (!exp || (exp * 1000) < new Date().getTime())
}

if (token && !tokenIsExpired(token))
  axios.defaults.headers.common['authorization'] = `Bearer ${token}`

try {
  axios.defaults.headers.common['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone
} catch (error) {
  axios.defaults.headers.common['timezone'] = 'America/Sao_Paulo'
}



export const ApiClient = axios