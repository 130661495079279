import React from 'react'
import Grid from '@material-ui/core/Grid'
import ListItem from '@material-ui/core/ListItem'
import ListSubheader from '@material-ui/core/ListSubheader'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import _ from 'lodash'

import { useConfirm } from 'material-ui-confirm'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { formatValue } from '../../../../../Utils/FormatValue'
import { checkoutActions } from '../../../../../Store/reducer/checkout'

const useStyles = makeStyles((theme) => ({
  root: {

  },
  subHeader: {
    background: '#F2F2F2'
  },
  title: {
    marginBlockStart: '0px',
    marginBlockEnd: '0px'
  },
  productItem: {
    borderBottom: '1px solid #f2f2f2',
    '&:last-child': {
      borderBottom: 'none'
    }
  }
}))

function Complements({ complements }) {
  const items = _.flatten(complements
    .map(comp => comp.items))
    .filter(item => !!item.quantity)

  return (
    items.map((item, index) =>
      <small style={{ display: 'block' }}>{`${item.quantity}x ${item.name}`}</small>
    )
  )
}

export const Items = () => {
  const classes = useStyles()
  const checkout = useSelector(state => state.checkout)
  const confirm = useConfirm()
  const dispatch = useDispatch()

  function handleRemoveItem({ quantity, product }) {
    confirm({
      cancellationText: 'Não',
      confirmationText: 'Sim',
      title: `Excluir item`,
      description: `Deseja realmente excluir o produto ${product.name}?`
    }).then(() => {
      quantity--
      dispatch({ type: checkoutActions.ADD_PRODUCT, payload: { quantity, product } })
    })
  }

  return (
    <div className={classes.root}>
      <ListSubheader className={classes.subHeader} component="div">
        Produtos
      </ListSubheader>
      {
        checkout.items.map((product) =>
          <ListItem key={product._id} className={classes.productItem}>
            <Grid
              container
              spacing={2}
              alignItems="center"
            >
              <Grid item xs={1}>
                {product.quantity}x
              </Grid>
              <Grid item xs={6}>
                <h4 className={classes.title}>{product.name}</h4>
                <Complements complements={product.complements} />
              </Grid>
              <Grid item xs={3}>
                {formatValue(product.price, 'money')}
              </Grid>
              <Grid item xs={2}>
                <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveItem({ product, quantity: product.quantity })}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </ListItem>
        )
      }
    </div>
  )
}