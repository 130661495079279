import React from 'react'
import Grid from '@material-ui/core/Grid'
import ListItem from '@material-ui/core/ListItem'
import ListSubheader from '@material-ui/core/ListSubheader'

import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { formatValue } from '../../../../../Utils/FormatValue'

const useStyles = makeStyles((theme) => ({
  root: {

  },
  subHeader: {
    background: '#F2F2F2'
  },
  item: {
    borderBottom: '1px solid #f2f2f2',
    '&:last-child': {
      borderBottom: 'none'
    }
  }
}))

export const Summary = () => {
  const classes = useStyles()
  const { shipping: { doNotCalculateShipping } } = useSelector(state => state.company)
  const {
    subTotal,
    deliveryFee,
    // discountCoupon,
    amount
  } = useSelector(state => state.checkout)

  const summaryItems = {
    'SubTotal': subTotal,
    'Taxa de Entrega': deliveryFee,
    // 'Cupom Desconto': discountCoupon,
    'Total': amount
  }

  return (
    <div className={classes.root}>
      <ListSubheader className={classes.subHeader} component="div">
        Totais
      </ListSubheader>
      {
        Object.keys(summaryItems).map((key) =>
          <ListItem key={key} className={classes.item}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6} justify="flex-start">{key}</Grid>
              <Grid item xs={6} justify="flex-end">{
                key === 'Taxa de Entrega' && doNotCalculateShipping ?
                  '(Consultar)'
                  : formatValue(summaryItems[key], 'money')
              }</Grid>
            </Grid>
          </ListItem>
        )
      }
    </div>
  )
}