/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Avatar from '@material-ui/core/Avatar'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import MapIcon from '@material-ui/icons/Map'
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import * as _ from 'lodash'
import statusOrder from '../../../Utils/statusOrder'

import { useTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { ApiClient } from '../../../Utils/Api'
import { formatValue } from '../../../Utils/FormatValue'
import { GetFullAddress } from '../../../Utils/GetFullAddress'
import { Spinner } from '../../../Components/Spinner'

const { REACT_APP_URL_API_STATIC } = process.env


const useStyles = makeStyles((theme) => ({
  root: {

  },
  productItem: {
    '& .badge': {
      color: 'var(--primary)',
      border: '1px solid var(--primary)',
      borderRadius: '4px',
      padding: '0px 3px',
      marginRight: '5px'
    }
  },
  summaryItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  title: {
    // background: 'var(--primary)',
    // color: 'white'
  },
  spinnerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    minHeight: '300px',
    minWidth: '300px'
  },
  payMethodIcon: {
    width: '50px',
    height: '50px',
    objectFit: 'contain',
    marginRight: '12px'
  }
}))

export const DialogViewOrder = ({
  open = false,
  setOpen,
  orderId
}) => {
  const theme = useTheme()
  const classes = useStyles()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [order, setOrder] = useState({})

  function handleClose() {
    setOpen(false)
  }

  useEffect(() => {
    if (open) loadOrder()
  }, [orderId, open])

  async function loadOrder() {
    const { data } = await ApiClient.get(`/customer/orders/${orderId}`)
    setOrder(data)
  }

  return (
    <Dialog
      className={classes.root}
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle className={classes.title} >Pedido #{_.padStart(order.sequence, 4, '0')}</DialogTitle>
      <DialogContent style={{ padding: '0px', background: '#f1f1f1' }}>
        {!order._id &&
          <div className={classes.spinnerContainer}>
            <Spinner />
          </div>
        }
        {order._id &&
          <>
            <Card style={{ margin: '12px' }}>
              <CardHeader
                avatar={<Avatar src={order.company?.brand?.logo} />}
                title={order.company?.name}
                subheader={`Pedido ${statusOrder[order.status]}`}
              />
            </Card>
            <Card style={{ margin: '12px' }}>
              <CardHeader subheader="Produtos" style={{ paddingBottom: '0px' }} />
              <CardContent>
                {order?.items?.map((product) => (
                  <div className={classes.productItem} key={product._id}>
                    <span className="badge">{product.quantity}</span>
                    <span>{product.name}</span>
                  </div>
                ))}
              </CardContent>
            </Card>
            <Card style={{ margin: '12px' }}>
              <CardHeader subheader="Entrega" style={{ paddingBottom: '0px' }} />
              <CardContent style={{ padding: '4px' }}>

                {!order.withdraw &&
                  <ListItem>
                    <MapIcon color="primary" style={{ marginRight: '12px' }} />
                    <ListItemText primary={GetFullAddress({ address: order.address })} />
                  </ListItem>
                }
                {order.withdraw &&
                  <ListItem>
                    <TransferWithinAStationIcon color="primary" style={{ marginRight: '12px' }} />
                    <ListItemText primary="Retirar no estabelecimento" />
                  </ListItem>
                }
              </CardContent>
            </Card>
            <Card style={{ margin: '12px' }}>
              <CardHeader subheader="Forma de Pagamento" style={{ paddingBottom: '0px' }} />
              <CardContent style={{ padding: '4px' }}>
                <ListItem>
                  <img className={classes.payMethodIcon} alt={_.get(order, 'paymentMethod.name', 'Dinheiro')} src={`${REACT_APP_URL_API_STATIC}/images/${_.get(order, 'paymentMethod.image', 'money.svg')}`} />
                  <ListItemText primary={_.get(order, 'paymentMethod.name', 'Dinheiro')} />
                </ListItem>
              </CardContent>
            </Card>
            <Card style={{ margin: '12px' }}>
              <CardHeader subheader="Totais" style={{ paddingBottom: '0px' }} />
              <CardContent>
                <div className={classes.summaryItem} >
                  <strong>SubTotal:</strong>
                  <span>{formatValue(order.subTotal, 'money')}</span>
                </div>
                <div className={classes.summaryItem} >
                  <strong>Taxa de Entrega:</strong>
                  <span>{formatValue(order.deliveryFee, 'money')}</span>
                </div>
                <div className={classes.summaryItem} >
                  <strong>Total:</strong>
                  <span>{formatValue(order.amount, 'money')}</span>
                </div>
              </CardContent>
            </Card>
          </>
        }
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={handleClose} color="secondary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  )
}