import React from 'react'
import Container from '@material-ui/core/Container'
import { useSelector } from 'react-redux'

import {
  CompanyInfoArea,
  Categories,
  CheckoutSummary,
} from './Blocks'

export const HomePage = () => {
  const { company } = useSelector(({ company }) => ({ company }))
  return (
    <>
      <CompanyInfoArea company={company} />
      <Container>
        <Categories />
        {company.isOpen && <CheckoutSummary />}
      </Container>
    </>
  )
}