import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import PersonPinIcon from '@material-ui/icons/PersonPin'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import ReceiptIcon from '@material-ui/icons/Receipt'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    alignItems: 'center',
    width: '100%',
    background: '#f2f2f2',
    bottom: '0px',
    position: 'fixed',
    left: '0px',
    zIndex: 1000
  },
}))

export const TabsComponent = ({ onChange }) => {
  const classes = useStyles()
  const [tabIndex, setTabIndex] = useState(0)

  const handleChange = (event, value) => {
    setTabIndex(value)
    onChange(value)
  }

  return (
    <Paper square className={classes.root}>
      <Tabs
        value={tabIndex}
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="secondary"
        textColor="secondary"
        aria-label="icon label tabs example"
      >
        <Tab icon={<FormatListBulletedIcon />} label="Produtos" />
        <Tab icon={<ReceiptIcon />} label="Pedidos" />
        <Tab icon={<PersonPinIcon />} label="Perfil" />
      </Tabs>

    </Paper>)

}

