/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import Avatar from '@material-ui/core/Avatar';
import _ from 'lodash'
import classnames from 'classnames'
import { Facebook, Twitter, Instagram, YouTube } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { formatValue } from '../../../Utils/FormatValue'

import styles from '../style'
import { GetFullAddress } from '../../../Utils/GetFullAddress';
const useStyles = makeStyles(styles);

export const CompanyInfoArea = ({ company = {} }) => {
  const classes = useStyles()

  const statusClassName = classnames(
    classes.status,
    { [classes.open]: company.isOpen },
    { [classes.closed]: !company.isOpen },
  )

  function getDeliveryTime() {
    const times = _.get(company, 'shipping.tax', []).map(({ time }) => time)
    const min = _.first(times)
    const max = _.last(times)
    const time = (min !== max ? `${min} à ${max}` : max)
    return !!time && (` ${time} minutos`)
  }

  function SocialLinks() {
    const icons = {
      'facebook': <Facebook className={classes.social_icon} />,
      'instagram': <Instagram className={classes.social_icon} />,
      'twitter': <Twitter className={classes.social_icon} />,
      'youtube': <YouTube className={classes.social_icon} />,
    }
    return Object.keys(icons).map((icon, index) => (
      company?.social[icon] &&
      <a key={`${icon}_${index}`} href={company.social[icon]} target="_blank">
        {icons[icon]}
      </a>
    ))
  }

  return (
    <>
      <img src={company?.brand?.cover} className={classes.cover} />
      <Avatar src={company?.brand?.logo} className={classes.avatar_large} />
      <div className={classes.info_company_container}>
        <h2>
          {company.name}
          <span className={statusClassName}>{company.isOpen ? 'Aberto' : 'Fechado'}</span>
        </h2>
        {!_.isEmpty(company?.social) &&
          <div className={classes.social_container}>
            <SocialLinks />
          </div>
        }
        <span style={{ marginBottom: '3px' }}>{GetFullAddress({ address: company.address })}</span>
        {company?.shipping?.allowsDelivery &&
          (
            <span>{getDeliveryTime()}
              {company?.shipping?.minimumValueForDelivery > 0
                && ` - Mínimo ${formatValue(company.shipping.minimumValueForDelivery, 'money')}`
              }
            </span>
          )}
      </div>
    </>
  )
}