import React from 'react'
import { RegisterComponent } from '../../../Components/Register'

export const Register = ({ changeNavigation }) => {
  return (
    <RegisterComponent
      onSuccessRegister={() => changeNavigation('Login')}
      onCancelRegister={() => changeNavigation('Login')}
      showLogo={true}
      showTerms={true}
    />
  )
}