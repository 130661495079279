import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { useTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { formatValue } from '../../../Utils/FormatValue'
import FirebaseService from '../../../Utils/FirebaseService'
import { DayOfWeekParse } from '../../../hooks/utils/DayofWeekParse'

const useStyles = makeStyles((theme) => ({
  root: {
    '& img': {
      width: 'calc(100% + 48px)',
      height: 'auto',
      'object-fit': 'cover',
      marginLeft: '-24px',
      boxShadow: '1px 7px 10px rgb(2,2,2,0.4)',
      marginBottom: '16px'
    },
    '& span.price-value': {
      color: 'red',
      fontSize: '1.6rem',
      fontWeight: 'bold'
    },
    '& div.price-container': {
      display: 'flex',
      justifyContent: 'flex-end'
    }
  },
  productDescription: {
    whiteSpace: 'pre-wrap'
  },
  subHeader: {
    background: '#F2F2F2',
    '& .MuiChip-root': {
      marginLeft: '10px'
    }
  },
  listRulesAvailiables: {
    width: 'calc(100% + 48px)',
    marginLeft: '-24px',
    marginTop: '12px',
    '& .MuiListItem-root': {
      display: 'flex',
      flexDirection: 'column'
    },
    '& .MuiListItemText-multiline': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%'
    }
  },
}))

function RulesAvailiables({ product }) {
  const classes = useStyles()
  return (
    _.size(_.get(product, 'times', [])) > 0 &&
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.listRulesAvailiables}
      subheader={
        <ListSubheader className={classes.subHeader} component="div" id="nested-list-subheader">
          Produto está disponível em
        </ListSubheader>
      }
    >
      <ListItem>
        {_.get(product, 'times', []).map(({ dayOfWeek, startHour, endHour }, index) => (
          <ListItemText primary={DayOfWeekParse(dayOfWeek)} secondary={`${startHour} até às ${endHour}`} />
        ))}
      </ListItem>
    </List >
  )
}

export const DialogProduct = ({ visible = false, dismiss, product }) => {
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles()

  useEffect(() => {
    setOpen(visible)
    if (visible) {
      FirebaseService.pageView('DialogProduct')
    }
  }, [visible])

  const handleClose = () => {
    setOpen(false)
    dismiss(false)
  }

  return (
    <Dialog
      className={classes.root}
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle>{product.name}</DialogTitle>
      <DialogContent>
        {product.photo && <img src={product.photo} alt={`imagem do produto ${product.name}`} />}
        <DialogContentText className={classes.productDescription}>
          {product.description}
        </DialogContentText>
        <div className='price-container'>
          <span className='price-value'>
            {formatValue(product.price, 'money')}
          </span>
        </div>
        <RulesAvailiables product={product} />
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={handleClose} color="secondary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
