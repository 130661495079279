export const PredictionsParse = ({
  description,
  place_id,
  structured_formatting: {
    main_text, secondary_text
  }
}) => ({
  description,
  place_id,
  main_text,
  secondary_text
})