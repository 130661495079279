import _ from 'lodash'
import Chip from '@material-ui/core/Chip'
import ListSubheader from '@material-ui/core/ListSubheader'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import { formatValue } from '../../../Utils/FormatValue'
import { Quantity } from '../../../Components/Quantity'

const useStyles = makeStyles((theme) => ({
  listComplements: {
    width: 'calc(100% + 48px)',
    marginLeft: '-24px',
  },
  subHeader: {
    background: '#F2F2F2',
    '& .MuiChip-root': {
      marginLeft: '10px'
    }
  },
  missingItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    '& .badge': {
      background: '#ccc',
      padding: '2px 10px',
      borderRadius: '10px',
      marginRight: '10px',
      color: '#757272',
      fontSize: '0.8rem'
    },
    '& .MuiListItemText-root': {
      textDecoration: 'line-through',
      color: '#a9a7a7',
    }
  }
}))

export const ListComplements = ({ complements, onUpdatePrice }) => {
  const classes = useStyles()

  function changeQuantity(item, quantity) {
    item.quantity = quantity
    calculatePrice()
  }

  function calculatePrice() {
    const _priceCalculated = _.flatten(complements
      .map(comp => comp.items))
      .filter(item => !!item.quantity)
      .reduce((acc, item) => acc + (item.quantity * item.price), 0)
    onUpdatePrice(_priceCalculated)
  }

  const getComplementItemsSorted = (complement) => _.orderBy(_.get(complement, 'items', []), ['active'], ['desc'])

  return (
    complements.map(complement =>
      <List component="nav" aria-labelledby="nested-list-subheader" className={classes.listComplements}
        subheader={
          <ListSubheader className={classes.subHeader} component="div" id="nested-list-subheader">
            {complement.name}
            {!!complement.min && !!complement.max && <Chip label={`Min: ${complement.min} - Max: ${complement.max}`} variant="outlined" size="small" />}
            {!complement.min && !!complement.max && <Chip label={`Max: ${complement.max}`} variant="outlined" size="small" />}
            {!!complement.min && !complement.max && <Chip label={`Min: ${complement.min}`} variant="outlined" size="small" />} 
          </ListSubheader>
        } >
        {getComplementItemsSorted(complement).map(item =>
          <ListItem>

            {_.get(item, 'active') && (
              <>
                <ListItemText primary={item.name} secondary={item.price ? `+ ${formatValue(item.price, 'money')}` : ''} />
                <Quantity onChange={quantity => changeQuantity(item, quantity)} />
              </>
            )}

            {!_.get(item, 'active') && (
              <div className={classes.missingItem}>
                <ListItemText primary={item.name} />
                <span className="badge">Em falta</span>
              </div>
            )}

          </ListItem>
        )}
      </List>)
  )
}
