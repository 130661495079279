import React, { useState, createContext } from 'react'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { InvertColor } from './InvertColor'

export const ThemeContext = createContext()


function makeContrastColor(color) {
  try {
    return InvertColor(color)
  } catch (error) {
    return color
  }
}

export const ThemeContextProvider = props => {
  const [theme, setTheme] = useState({})

  const getTheme = ({ primary = '#fff', secondary = '#333' }) =>
    createMuiTheme({
      palette: {
        primary: {
          main: primary,
          contrastText: makeContrastColor(primary)
        },
        secondary: {
          main: secondary,
          contrastText: makeContrastColor(secondary)
        },
      },
      overrides: {
        MuiButton: {
         containedPrimary: {
           color: makeContrastColor(primary)
         }, 
         containedSecondary: {
          color: makeContrastColor(secondary)
         }
        }
      }
    })

  return (
    <ThemeProvider theme={getTheme({
      primary: theme.primary,
      secondary: theme.secondary
    })}>
      <ThemeContext.Provider value={[theme, setTheme]}>
        {props.children}
      </ThemeContext.Provider>
    </ThemeProvider>
  )
}