import React from 'react'
import ListItemText from '@material-ui/core/ListItemText'
import TextField from '@material-ui/core/TextField'
import { formatValue } from '../../Utils/FormatValue'
import InputMask from "react-input-mask"

export const Input = ({
  label,
  field,
  type,
  required,
  form,
  setForm,
  editable = true,
  mask,
  variant = 'outlined',
  inputMode = false,
  style = {},
  onBlur = () => { }
}) => {

  let propsFromType = {}

  if (type === 'date')
    propsFromType = {
      InputLabelProps: { shrink: true }
    }

  const format = (value) => ({
    'date': formatValue(value, 'date'),
    'text': value,
    'mail': value,
    'number': value
  })


  return (
    inputMode ?
      <InputMask
        mask={mask}
        defaultValue={form[field]}
        onChangeCapture={({ target: { value } }) => setForm({ ...form, [field]: value })}
        type={type}
        maskChar=" "
        disabled={!editable}
        key={field}
        onBlur={onBlur}
      >
        {(inputProps) =>
          <TextField
            fullWidth
            variant={variant}
            label={`${label} ${required ? '*' : ''}`}
            {...inputProps}
            {...propsFromType}
            disabled={!editable}
            {...style}
          />
        }
      </InputMask>
      :
      <ListItemText primary={label} secondary={(format(form[field])[type]) || ' - - '} />
  )
}