import jwt_decode from 'jwt-decode'
import axios from 'axios'
import { KeyStorage } from '../../Utils/Constants'

export const userActions = {
  LOGIN: '@USER/LOGIN',
  LOGOUT: '@USER/LOGOUT',
  SET_AVATAR: '@USER/SET_AVATAR',
  SET_ADDRESS: '@USERSET_ADDRESS',
}

const INITIAL_STATE = {
  isLogged: isLoggedCheck(localStorage.getItem(KeyStorage.TOKEN)),
  user: JSON.parse(localStorage.getItem(KeyStorage.USER)),
  address: []
}

function isLoggedCheck(token) {
  try {
    return !tokenIsExpired(token)
  } catch (error) {
    return false
  }
}

function tokenIsExpired(token) {
  const { exp } = jwt_decode(token, { complete: true })
  return (!exp || (exp * 1000) < new Date().getTime())
}

export default function user(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case userActions.LOGIN: {
      const { token, user } = payload

      localStorage.setItem(KeyStorage.TOKEN, token)
      localStorage.setItem(KeyStorage.USER, JSON.stringify(user))

      axios.defaults.headers.common['authorization'] = `Bearer ${token}`

      return {
        isLogged: true,
        user
      }
    }
    case userActions.LOGOUT: {
      localStorage.removeItem(KeyStorage.TOKEN)
      localStorage.removeItem(KeyStorage.USER)

      axios.defaults.headers.common['authorization'] = undefined

      return { isLogged: false }
    }
    case userActions.SET_AVATAR: {
      return { ...state, avatar: payload }
    }
    case userActions.SET_ADDRESS: {
      return { ...state, address: payload }
    }
    default:
      return state
  }
}