export function LoadScript({ id, url, callback }) {
  let script = document.createElement('script')
  const loaded = !!document.getElementById(id)
  if (loaded) return
  script.type = 'text/javascript'
  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null
        callback()
      }
    }
  } else {
    script.onload = () => callback()
  }

  script.src = url
  script.id = id
  document.getElementsByTagName('head')[0].appendChild(script)
}