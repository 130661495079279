/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import ListItem from '@material-ui/core/ListItem'
import Button from '@material-ui/core/Button'
import ListSubheader from '@material-ui/core/ListSubheader'
import classnames from 'classnames'
import { toast } from 'react-toastify'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import PaymentMethods from '../../../../../Utils/PaymentMethods'
import { checkoutActions } from '../../../../../Store/reducer/checkout'
import { Input } from '../../../../../Components/Input'


const useStyles = makeStyles((theme) => ({
  root: {

  },
  subHeader: {
    background: '#F2F2F2'
  },
  itemGrid: {
    padding: '4px !important',
    '& .MuiButton-text': {
      textTransform: 'capitalize'
    }
  },
  itemSeparator: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: 'var(--primary)',
    borderBottom: '1px solid #f2f2f2',
    width: '100%',
    marginBottom: '8px'
  },
  button: {
    lineHeight: 'initial',
    textAlign: 'left'
  },
  payMethodSelect: {
    background: 'greenyellow'
  }
}))

export const PayInformation = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { paymentMethod: paymentAllowedOnCompany } = useSelector(state => state.company)
  const { paymentMethod, needChange, changeAmount, amount } = useSelector(state => state.checkout)

  const [payGrouped, setPayGrouped] = useState([])
  const [form, setForm] = useState({})


  useEffect(() => {
    groupPayMethods(paymentAllowedOnCompany)
  }, [paymentAllowedOnCompany])

  function handleChangeAmount() {
    try {
      if (!!form.changeAmount && form.changeAmount < amount)
        return toast.warn('O valor de troco precisa ser maior que o valor da compra', { autoClose: 3000 })

      dispatch({
        type: checkoutActions.CHANGE_PAYMENT_METHOD, payload: {
          paymentMethod,
          needChange: !!form.changeAmount,
          changeAmount: form.changeAmount
        }
      })
    } catch (error) {
      toast.warn('Informe um valor válido para solicitar troco', { autoClose: 3000 })
      setForm({ ...form, changeAmount: null })
    }
  }

  function groupPayMethods(methods) {
    const result = []
    Object.keys(PaymentMethods).forEach(key => {
      result.push({
        method: key,
        options: methods.filter(pay => pay.type === key)
          .map(({ slug, description }) => ({
            slug,
            description,
          }))
      })
    })
    setPayGrouped(result.filter(r => !!r.options.length))
  }

  function handleSetPaymentMethod(method) {
    dispatch({
      type: checkoutActions.CHANGE_PAYMENT_METHOD, payload: {
        paymentMethod: method,
        needChange,
        changeAmount
      }
    })
  }

  function getClassItem(slug) {
    return classnames(
      classes.status,
      { [classes.payMethodSelect]: paymentMethod === slug },
    )
  }

  return (
    !!payGrouped.length &&
    <div className={classes.root}>
      <ListSubheader className={classes.subHeader} component="div">
        Forma de pagamento
      </ListSubheader>
      {payGrouped.map(({ method, options }) =>
        <ListItem key={method} style={{ flexDirection: 'column' }}>
          <span className={classes.itemSeparator}>{PaymentMethods[method]}</span>
          <Grid container spacing={2} alignItems="center">
            {options.map(({ slug, description }) =>
              <>
                <Grid
                  item
                  key={`${slug}|${method}`}
                  xs={6}
                  justify="flex-start"
                  className={getClassItem(`${slug}|${method}`)}>
                  <Button className={classes.button}
                    onClick={() => handleSetPaymentMethod(`${slug}|${method}`)}>
                    {description}
                  </Button>
                </Grid>
                {paymentMethod === 'dinheiro|MONEY' && slug === 'dinheiro' && (
                  <Grid
                    item
                    xs={6}
                    justify="flex-start"
                    key="dinheiro_dinheiro_troco"
                    >
                    <Input
                      form={form}
                      setForm={setForm}
                      label="Troco para?"
                      field="changeAmount"
                      type='number'
                      inputMode={true}
                      onBlur={handleChangeAmount}
                    />
                  </Grid>
                )}
              </>
            )}

          </Grid>
        </ListItem>
      )}
    </div>
  )

}