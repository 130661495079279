/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Container from '@material-ui/core/Container'
import { LoginComponent } from '../../../Components/Login'

export const Login = ({ changeNavigation }) => {
  return (
    <Container maxWidth="sm" style={{
      height: 'calc(100vh - 80px)',
      marginBottom: '50px',
      display: 'flex'
    }}>
      <LoginComponent
        showLogo={true}
        showTerms={true}
        onRegister={() => changeNavigation('Register')}
        onForgotPassword={() => changeNavigation('ForgotPassword')}
        onLoginSuccess={() => changeNavigation('Profile')}
      />
    </Container>
  )
}