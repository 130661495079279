/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles'
import { ApiClient } from '../../Utils/Api'
import { toast } from 'react-toastify'
import { Input } from '../Input'
import { useDispatch, useSelector } from 'react-redux'
import { ValidationForm } from '../../Utils/ValidationForm'
import { FormatError } from '../../Utils/FormatError'
import { Spinner } from '../Spinner'
import { userActions } from '../../Store/reducer/user'
import FirebaseService from '../../Utils/FirebaseService'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    '& .MuiList-root': {
      width: '90%'
    },
    '& .MuiAvatar-root': {
      width: '120px',
      height: '120px',
      marginBottom: '16px'
    }
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '90%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '& > button': {
      marginBottom: '12px'
    }
  },
  termsContainer: {
    width: '90%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '& a': {
      color: 'var(--primary)',
      fontWeight: '500'
    }
  }
}))


export const LoginComponent = ({
  onLoginSuccess,
  onRegister,
  onForgotPassword,
  showLogo = true,
  showTerms = true
}) => {
  const classes = useStyles()
  const company = useSelector(state => state.company)
  const [form, setForm] = useState({})
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const fields = [
    { field: 'mail', label: 'E-mail', type: 'mail', required: true },
    { field: 'password', label: 'Senha', type: 'password', required: true },
  ]

  async function handleLogin() {
    setLoading(true)
    try {
      const { erros, isValid } = ValidationForm({ form, fields })
      if (!isValid) {
        toast.dismiss()
        toast.warn(erros.join('\n'), { autoClose: false, closeButton: true })
        return
      }
      const { data } = await ApiClient.post('/public/customer/login', form)
      FirebaseService.registerEvent('login', { method: 'jwt' })
      FirebaseService.setUserId(data._id)
      dispatch({ type: userActions.LOGIN, payload: data })
      onLoginSuccess()
    } catch (error) {
      const errors = FormatError({ error })
      toast.warn(errors.join('\n'))
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className={classes.root}>
      {showLogo && <Avatar src={company.brand.logo} />}
      <List fullWidth>
        {fields.map((element) =>
          <ListItem key={element.field}>
            <Input {...element} setForm={setForm} form={form} inputMode={true} />
          </ListItem>
        )}
      </List>
      {showTerms &&
        <div className={classes.termsContainer}>
          Ao clicar em entrar abaixo, estará concordando com os <a href="#" target="_blank">Termos de Serviço</a> e a <a href="#" target="_blank">Política de privacidade</a> da Plataforma Kuru
        </div>
      }
      <div className={classes.buttonsContainer}>
        {loading && <Spinner />}
        {!loading &&
          <>
            <Button fullWidth variant="contained" color="primary" onClick={handleLogin}>Entrar</Button>
            {onRegister && <Button fullWidth variant="outlined" color="primary" onClick={onRegister}>Criar conta</Button>}
            {onForgotPassword && <Button fullWidth size="small" color="primary" onClick={onForgotPassword} >Esqueci minha senha</Button>}
          </>
        }
      </div>
    </div>
  )
}