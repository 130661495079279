import { useState, useEffect } from 'react'
import { Constants } from '../Utils/Constants'
import { LoadScript } from '../Utils/LoadScript'
import { useDebouncedEffect } from './useDebouncedEffect'
import { DetailAddressParse } from './utils/DetailAddressParse'
import { PredictionsParse } from './utils/PredictionsParse'

export function useMaps({ query }) {
  const [predictions, setPredictions] = useState([])
  const [notFound, setNotFound] = useState(false)

  useEffect(() => {
    LoadScript({
      id: 'script-maps',
      url: `https://maps.googleapis.com/maps/api/js?key=${Constants.googleKey}&libraries=places`,
      callback: () => {
      }
    })
  }, [])

  useDebouncedEffect(() => {
    getPredictions()
    if (query.length === 0) {
      setPredictions([])
      setNotFound(false)
    }
  }, 500, [query])


  function getPredictions() {
    if (!query) return
    const options = {
      types: ['geocode'],
      input: query,
      componentRestrictions: { country: 'BR' }
    }
    const autoCompleteService = new window.google.maps.places.AutocompleteService()
    autoCompleteService.getPlacePredictions(options, (predictions, status) => {
      if (status === 'OK') {
        setPredictions(predictions.map(PredictionsParse))
        setNotFound(false)
      }
      if (status === 'ZERO_RESULTS') {
        setNotFound(true)
        setPredictions([])
      }
    })
  }

  function getDetails(placeId) {
    return new Promise((resolve) => {
      const detailService = new window.google.maps.places.PlacesService(document.createElement('span'))
      detailService.getDetails({ placeId }, (place, status) => {
        if (status !== 'OK') resolve(null)
        resolve(DetailAddressParse(place))
      })
    })
  }

  return { predictions, notFound, getDetails }
}