import React, { useEffect } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeContextProvider } from './Context/ThemeContext'
import { ToastContainer } from 'react-toastify'
import { ConfirmProvider } from 'material-ui-confirm'
import { Provider } from 'react-redux'
import {
  BrowserRouter,
  Switch,
  Route
} from 'react-router-dom'
import { TabsPage } from './Views/Tabs'
import { NotFoundPage } from './Views/NotFound';

import store from './Store'
import FirebaseService from './Utils/FirebaseService'

import 'react-toastify/dist/ReactToastify.css'
import './styles.css'

function App() {

  useEffect(() => {
    FirebaseService.initilize()
  }, [])

  return (
    <React.Fragment>
      <CssBaseline />
      <ThemeContextProvider>
        <ConfirmProvider>
          <Provider store={store}>
            <BrowserRouter>
              <Switch>
                <Route exact path='/' component={NotFoundPage} />
                <Route exact path='/:slug' component={TabsPage} />
              </Switch>
            </BrowserRouter >
            <ToastContainer
              position='top-right'
              autoClose={5000}
              hideProgressBar={true} />
          </Provider>
        </ConfirmProvider>
      </ThemeContextProvider>
    </React.Fragment>
  );
}

export default App;
