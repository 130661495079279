export function ValidationForm({ form, fields }) {
  const erros = []
  const allFields = fields.map(field => field.field)
  allFields.forEach(field => {
    const _fieldConfig = fields.find(f => f.field === field)
    if (_fieldConfig) {
      if (_fieldConfig.required && !form[field])
        erros.push(`O campo ${_fieldConfig.label} é obrigatório`)
    }
  })
  return { erros, isValid: erros.length === 0 }
}
