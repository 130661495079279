/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItemText from '@material-ui/core/ListItemText'
import MapIcon from '@material-ui/icons/Map'
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation'
import Haversine from 'haversine'
import _ from 'lodash'

import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { GetFullAddress } from '../../../../../Utils/GetFullAddress'
import { ApiClient } from '../../../../../Utils/Api'
import { userActions } from '../../../../../Store/reducer/user'
import { Spinner } from '../../../../../Components/Spinner'
import { checkoutActions } from '../../../../../Store/reducer/checkout'
import { DialogLogin } from './DialogLogin'
import { DialogRegister } from './DialogRegister'
import { DialogChoiceUserNotLogged } from './DialogChoiceNotLogged'
import FirebaseService from '../../../../../Utils/FirebaseService'

const useStyles = makeStyles((theme) => ({
  root: {

  },
  subHeader: {
    background: '#F2F2F2'
  },
  addressSelect: {
    background: 'greenyellow !important'
  },
  loadingContainer: {
    padding: '10px',
    textAlign: 'center'
  }
}))

export const Shipping = () => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [showLogin, setShowLogin] = useState(false)
  const [showUserOptions, setShowUserOptions] = useState(false)
  const [showRegister, setShowRegister] = useState(false)
  const user = useSelector(state => state.user)
  const checkout = useSelector(state => state.checkout)
  const { shipping: {
    allowsDelivery,
    allowsWithdrawal,
    doNotCalculateShipping,
    freeShippingAlways,
    valueForFreeShipping,
    tax
  }, address: { latitude, longitude } } = useSelector(state => state.company)
  const dispatch = useDispatch()

  function handleSelectItem(address, { withDrawal = false } = {}) {

    if (withDrawal)
      return dispatch({ type: checkoutActions.WITHDRAW })

    if (doNotCalculateShipping || freeShippingAlways) {
      return dispatch({
        type: checkoutActions.SELECT_ADDRESS,
        payload: {
          deliveryFee: 0,
          address
        }
      })
    }

    if (valueForFreeShipping) {
      if (valueForFreeShipping <= checkout.subTotal)
        return dispatch({
          type: checkoutActions.SELECT_ADDRESS,
          payload: {
            deliveryFee: 0,
            address
          }
        })
    }

    const companyLocation = {
      latitude,
      longitude
    }

    const addressLocation = {
      latitude: address.lat,
      longitude: address.lng
    }

    const distance = Haversine(companyLocation, addressLocation)
    let deliveryFee = 0.0
    const values = tax.filter(t => t.km < distance)
    if (values.length > 0) {
      const { fee } = _.last(values) || { fee: 0 }
      deliveryFee = fee
    }
    return dispatch({
      type: checkoutActions.SELECT_ADDRESS,
      payload: {
        deliveryFee,
        address
      }
    })
  }

  useEffect(() => {
    if (user.isLogged)
      getAddressForUser()
  }, [user.isLogged])

  async function getAddressForUser() {
    setLoading(true)
    try {
      const { data } = await ApiClient.get('/customer/profile/me')
      dispatch({ type: userActions.SET_ADDRESS, payload: _.get(data, 'address', []) })
    } finally {
      setLoading(false)
    }
  }

  function handleCloseLogin() {
    setShowLogin(false)
  }

  function handleCloseUserOptions() {
    setShowUserOptions(false)
  }

  function handleCloseRegister() {
    setShowRegister(false)
  }

  function handleSelectUserOptions(option) {
    handleCloseUserOptions()
    switch (option) {
      case 'login':
        setShowLogin(true)
        FirebaseService.registerEvent({ name: 'action:shipping:login' })
        break;
      case 'register':
        setShowRegister(true)
        FirebaseService.registerEvent({ name: 'action:shipping:register' })
        break;
      default:
        break;
    }
  }

  return (
    <div className={classes.root}>
      <ListSubheader className={classes.subHeader} component="div">
        Entrega
      </ListSubheader>

      {loading &&
        <div className={classes.loadingContainer}>
          <Spinner />
        </div>
      }

      {!user.isLogged && (
        <ListItem button onClick={() => setShowUserOptions(true)}>
          <ListItemText style={{ color: 'red' }} primary="Efetue o login para listar os endereços" />
        </ListItem>
      )}

      <DialogLogin
        open={showLogin}
        onClose={handleCloseLogin}
        onLoginSuccess={handleCloseLogin}
      />

      <DialogRegister
        open={showRegister}
        onClose={handleCloseRegister}
        onRegisterSuccess={handleCloseRegister}
      />

      <DialogChoiceUserNotLogged
        text="Para continuar utilize uma das opções abaixo"
        open={showUserOptions}
        onSelect={handleSelectUserOptions}
        onClose={handleCloseUserOptions}
      />

      {allowsDelivery && user?.address?.length === 0 && user.isLogged && !loading && (
        <ListItem>
          <ListItemText primary="Você ainda não cadastrou nenhum endereço, acesse seu perfil e cadastre o primeiro endereço" />
        </ListItem>
      )}

      {allowsDelivery && !loading && user?.address?.map((address) =>
        <ListItem
          onClick={() => handleSelectItem(address)}
          className={{
            [classes.addressSelect]: _.get(checkout, 'address._id') === address._id
          }}
          button
          key={address}
        >
          <MapIcon color="primary" style={{ marginRight: '12px' }} />
          <ListItemText primary={GetFullAddress({ address })} />
        </ListItem>
      )}

      {allowsWithdrawal && (
        <ListItem button
          onClick={() => handleSelectItem(undefined, { withDrawal: true })}
          className={{
            [classes.addressSelect]: !!_.get(checkout, 'withdraw')
          }}
        >
          <TransferWithinAStationIcon color="primary" style={{ marginRight: '12px' }} />
          <ListItemText primary="Retirar no estabelecimento" />
        </ListItem>
      )}

    </div>
  )
}

