/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 50,
  },
  input: {
    marginLeft: theme.spacing(1),
    width: '20px'
  },
  iconButton: {
    padding: 10,
  }
}))

function usePrevious(value) {
  const ref = useRef(null);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const Quantity = ({ onChange, quantity = 0 }) => {
  const classes = useStyles()
  const [internalQuantity, setInternalQuantity] = useState(quantity)
  const prev = usePrevious(quantity)

  useEffect(() => { 
    setInternalQuantity(quantity)
  }, [quantity])


  useEffect(() => {
    if (prev !== null && prev !== internalQuantity)
      onChange(internalQuantity)
  }, [internalQuantity])

  function add() {
    setInternalQuantity(internalQuantity + 1)
  }

  function remove() {
    if (internalQuantity <= 0)
      setInternalQuantity(0)
    else
      setInternalQuantity(internalQuantity - 1)
  }

  return (
    <Box>
      <IconButton className={classes.iconButton} style={{ color: 'red' }} onClick={remove} aria-label="Remover">
        <RemoveCircleIcon />
      </IconButton>
      {internalQuantity}
      <IconButton className={classes.iconButton} style={{ color: 'green' }} onClick={add} aria-label="Adicionar">
        <AddCircleIcon />
      </IconButton>
    </Box>
  )
}