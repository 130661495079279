import firebase from 'firebase'
import { FirebaseConfig } from './Constants'

class FirebaseService {

  initilize() {
    firebase.initializeApp(FirebaseConfig)
    firebase.analytics()
  }

  registerEvent({ name, params = null }) {
    firebase.analytics().logEvent(name, params)
  }

  pageView({ name, params = null }) {
    firebase.analytics().logEvent('screen_view', { screen_name: name, ...params })
  }

  registerError({ description, isFatal = false }) {
    firebase.analytics().logEvent('exception', { description, fatal: isFatal })
  }

  setUserId(userId) {
    firebase.analytics().setUserId(userId)
  }

}

export default new FirebaseService()