import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Grid from '@material-ui/core/Grid'

import { useTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import {
  EditAddress,
  PredictionsEmpty,
  PredictionsList,
  PredictionsNotFound,
  SearchTextInput
} from './Blocks'
import { useMaps } from '../../../hooks/useMaps'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialogContent-root': {
      flex: 1
    }
  },
}))

export const DialogAddress = ({ visible = false, dismiss, data = null }) => {
  const [open, setOpen] = useState(false)
  const [query, setQuery] = useState('')
  const [address, setAddress] = useState(data)

  const { predictions, notFound, getDetails } = useMaps({ query })

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles()

  useEffect(() => {
    setOpen(visible)
  }, [visible])

  function handleClose(data = null) {
    setQuery('')
    setAddress(null)
    setOpen(false)
    dismiss(data)
  }

  function handleConfirm() {
    handleClose(address)
  }

  async function getDetailsWithFormAddress(place_id) {
    const address = await getDetails(place_id)
    setAddress(address)
  }

  return (
    <Dialog
      className={classes.root}
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle>Novo Endereço</DialogTitle>
      <DialogContent>
        {
          !address &&
          <>
            <SearchTextInput query={query} setQuery={setQuery} />
            <PredictionsList predictions={predictions} getDetails={getDetailsWithFormAddress} />
            <PredictionsNotFound predictions={predictions} notFound={notFound} />
            <PredictionsEmpty predictions={predictions} notFound={notFound} />
          </>
        }
        {address &&
          <EditAddress
            address={address}
            onChange={address => setAddress(address)}
          />
        }
      </DialogContent>
      <DialogActions>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center">
          <Button size="small" onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          <Button size="small" variant="contained" onClick={handleConfirm} color="primary">
            Confirmar
        </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}