import React, { useState } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'

import { ApiClient } from '../../../Utils/Api'
import { makeStyles } from '@material-ui/core/styles'
import { Input } from '../../../Components/Input'
import { ValidationForm } from '../../../Utils/ValidationForm'
import { FormatError } from '../../../Utils/FormatError'
import { toast } from 'react-toastify'
import { Spinner } from '../../../Components/Spinner'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    marginBottom: '50px',
    alignItems: 'center',
    padding: '50px 0px',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    '& .MuiList-root': {
      width: '90%'
    }
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '90%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '& > button': {
      marginBottom: '12px'
    }
  },
}))

export const ChangePassword = ({ changeNavigation }) => {
  const classes = useStyles()
  const [form, setForm] = useState({})
  const [loading, setLoading] = useState(false)

  const fields = [
    { field: 'currentPassword', label: 'Senha atual', type: 'password', required: true },
    { field: 'newPassword', label: 'Nova Senha', type: 'password', required: true },
    { field: 'confirmNewPassword', label: 'Confirmação da nova senha', type: 'password', required: true },
  ]

  async function handleSave() {
    setLoading(true)
    try {
      const { erros, isValid } = ValidationForm({ form, fields })
      if (!isValid) {
        toast.dismiss()
        toast.warn(erros.join('\n'), { autoClose: false, closeButton: true })
      }
      await ApiClient.put('/customer/profile/changepassword/me', form)
      toast.success('Senha alterada com sucesso!')
      changeNavigation('Profile')
    } catch (error) {
      const errors = FormatError({ error })
      toast.warn(errors.join('\n'))
    }
    finally {
      setLoading(false)
    }
  }

  return (
    <Container>
      <div className={classes.root}>
        <List fullWidth>
          {fields.map(element =>
            <ListItem>
              {Input({ ...element, setForm, form, inputMode: true })}
            </ListItem>
          )}
        </List>
        <div className={classes.buttonsContainer}>
          {loading && <Spinner />}
          {!loading && (
            <>
              <Button fullWidth variant="contained" color="primary" onClick={handleSave}>Alterar senha</Button>
              <Button fullWidth size="small" color="primary" onClick={() => changeNavigation('Profile')} >Voltar ao Perfil</Button>
            </>
          )}
        </div>
      </div>
    </Container>
  )

}