import React from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import TextField from '@material-ui/core/TextField'

export const SearchTextInput = ({ query, setQuery }) => (
  <TextField
    fullWidth
    variant="outlined"
    placeholder="Buscar endereço ou CEP"
    onChange={({ target: { value } }) => setQuery(value)}
    value={query}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
  />
)