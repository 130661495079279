import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import _ from 'lodash'

import { useTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { Items } from './Blocks/Items'
import { Summary } from './Blocks/Summary'
import { Shipping } from './Blocks/Shipping'
import { DialogChoiceUserNotLogged } from './Blocks/DialogChoiceNotLogged'
import { DialogLogin } from './Blocks/DialogLogin'
import { useDispatch, useSelector } from 'react-redux'
import { DialogRegister } from './Blocks/DialogRegister'
import { DialogForgotPassword } from './Blocks/DialogForgotPassword'
import { ApiClient } from '../../../../Utils/Api'
import { toast } from 'react-toastify'
import { FormatError } from '../../../../Utils/FormatError'
import { Spinner } from '../../../../Components/Spinner'
import { checkoutActions } from '../../../../Store/reducer/checkout'
import FirebaseService from '../../../../Utils/FirebaseService'
import { PayInformation } from './Blocks/PayInformation'

const useStyles = makeStyles((theme) => ({
  root: {

  }
}))

export const DialogCheckout = ({
  open = false,
  setOpen
}) => {
  const theme = useTheme()
  const user = useSelector(state => state.user)
  const checkout = useSelector(state => state.checkout)
  const company = useSelector(state => state.company)
  const dispatch = useDispatch()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [showUserOptions, setShowUserOptions] = useState(false)
  const [showLogin, setShowLogin] = useState(false)
  const [showRegister, setShowRegister] = useState(false)
  const [showForgotPassword, setShowForgotPassword] = useState(false)

  function handleClose() {
    setOpen(false)
  }

  function handleDone() {
    if (!user.isLogged) {
      setShowUserOptions(true)
      return
    }
    processOrder()
  }

  async function processOrder() {
    FirebaseService.registerEvent('checkout_progress')
    setLoading(true)
    try {
      const sender = checkout
      sender.company = company._id

      if (!!_.get(sender, 'address._id')) delete sender.address._id
      // if (!_.get(sender, 'paymentMethod')) return toast.warn('Você precisa selecionar uma forma de pagamento', { autoClose: 3000 })

      delete sender.count

      await ApiClient.post('order', checkout)
      toast.success('Pedido realizado com sucesso!', { autoClose: 3000 })
      FirebaseService.registerEvent('purchase', { company: company._id })
      dispatch({ type: checkoutActions.FINISHED_ORDER })
      setOpen(false)
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    } catch (error) {
      const errors = FormatError({ error })
      toast.warn(errors.join('\n'))
    } finally {
      setLoading(false)
    }
  }

  function handleCloseUserOptions() {
    setShowUserOptions(false)
  }

  function handleCloseLogin() {
    setShowLogin(false)
  }

  function handleCloseRegister() {
    setShowRegister(false)
  }

  function handleCloseForgotPassword() {
    setShowForgotPassword(false)
  }

  function handleSelectUserOptions(option) {
    handleCloseUserOptions()
    switch (option) {
      case 'login':
        setShowLogin(true)
        break;
      case 'register':
        setShowRegister(true)
        break;
      default:
        break;
    }
  }

  return (
    <Dialog
      className={classes.root}
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}>
      <DialogTitle>Carrinho de compras</DialogTitle>
      <DialogContent style={{ padding: '4px' }}>
        <Items />
        <Shipping />
        <PayInformation />
        <Summary />
        <DialogChoiceUserNotLogged
          open={showUserOptions}
          onSelect={handleSelectUserOptions}
          onClose={handleCloseUserOptions}
        />
        <DialogLogin
          open={showLogin}
          onLoginSuccess={handleCloseLogin}
          onClose={handleCloseLogin}
          onForgotPassword={handleCloseLogin}
        />
        <DialogRegister
          open={showRegister}
          onClose={handleCloseRegister}
          onRegisterSuccess={handleCloseRegister}
        />
        <DialogForgotPassword
          open={showForgotPassword}
          onClose={handleCloseForgotPassword}
        />
      </DialogContent>
      <DialogActions>
        {loading &&
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center">
            <Spinner />
          </Grid>
        }
        {!loading &&
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center">
            <Button onClick={handleClose} variant="outlined" color="primary">
              Continuar Comprando
            </Button>
            <Button onClick={handleDone} variant="contained" color="primary">
              Finalizar
            </Button>
          </Grid>
        }


      </DialogActions>
    </Dialog>
  )
}