/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState, } from 'react'
import _ from 'lodash'
import { Spinner } from '../../Components/Spinner'
import { useParams } from 'react-router'
import { ThemeContext } from '../../Context/ThemeContext'
import { ApiClient } from '../../Utils/Api'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { productsActions } from '../../Store/reducer/products'
import { companyActions } from '../../Store/reducer/company'
import { HomePage } from '../Home'
import { ProfilePage } from '../Profile'

import {
  TabPanel,
  TabsComponent
} from './Blocks'
import { OrderHistoryPage } from '../Order'
import FirebaseService from '../../Utils/FirebaseService'
import { NotFoundPage } from '../NotFound'


const useStyles = makeStyles({
  spinner: {
    display: 'flex',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center'
  }
})


export const TabsPage = () => {
  const { slug } = useParams()
  const { company } = useSelector(({ company }) => ({ company }))
  const [, setTheme] = useContext(ThemeContext)
  const [currentTab, setCurrentTab] = useState(0)
  const [notFoundCompany, setNotFoundCompany] = useState(false)
  const dispatch = useDispatch()
  const classes = useStyles()

  useEffect(() => {
    loadCompany()
  }, [slug])

  useEffect(() => {
    applyColors()
    loadProducts()
  }, [company])

  async function loadCompany() {
    if (company._id) return
    try {
      const { data } = await ApiClient.get(`/public/company/${slug}`)
      dispatch({ type: companyActions.SET_COMPANY, payload: data })

      document.getElementById('favicon').href = _.get(data, 'brand.logo')
      document.getElementById('apple-icon').href = _.get(data, 'brand.logo')
      document.title = _.get(data, 'name')

      FirebaseService.pageView('Home', { slug })
    } catch (error) {
      setNotFoundCompany(true)
    }
  }

  async function loadProducts() {
    if (company._id) return
    const { data } = await ApiClient.get(`/public/company/${slug}/products`)
    dispatch({ type: productsActions.SET_PRODUCTS, payload: data })
  }

  function applyColors() {
    const { documentElement: { style } } = document
    const primary = _.get(company, 'brand.primaryColor', '#571845')
    const secondary = _.get(company, 'brand.secondColor', '#333')
    style.setProperty('--primary', primary)
    style.setProperty('--secondary', secondary)
    setTheme({ primary, secondary })
  }

  const tabs = [
    { index: 0, component: <HomePage />, style: { marginBottom: '150px' } },
    { index: 1, component: <OrderHistoryPage /> },
    { index: 2, component: <ProfilePage /> }
  ]


  return (
    <>
      {
        _.get(company, '_id') &&
        <div className={classes.wrapper}>
          {tabs.map(tab =>
            <TabPanel key={tab.index} value={currentTab} index={tab.index} style={{ ...tab.style || {} }}>
              {tab.component}
            </TabPanel>
          )}
          <TabsComponent onChange={(tabIndex) => setCurrentTab(tabIndex)} />
        </div>
      }
      {
        !notFoundCompany && !_.get(company, '_id') &&
        <div className={classes.spinner}>
          <Spinner />
        </div>
      }
      {notFoundCompany && <NotFoundPage title={slug} subTitle="A empresa informada não foi localizada" />}
    </>
  )
}