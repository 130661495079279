import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import {
  Profile,
  Login,
  Register,
  ChangePassword,
  ForgotPassword
} from './Blocks'


export const ProfilePage = () => {
  const user = useSelector(state => state.user)
  const [navigation, setNavigation] = useState(user.isLogged ? 'Profile' : 'Login')

  useEffect(() => {
    setNavigation(user.isLogged ? 'Profile' : 'Login')
  }, [user.isLogged])


  return (
    <>
      {navigation === 'Login' && <Login changeNavigation={nav => setNavigation(nav)} />}
      {navigation === 'Register' && <Register changeNavigation={nav => setNavigation(nav)} />}
      {navigation === 'Profile' && <Profile changeNavigation={nav => setNavigation(nav)} />}
      {navigation === 'ChangePassword' && <ChangePassword changeNavigation={nav => setNavigation(nav)} />}
      {navigation === 'ForgotPassword' && <ForgotPassword changeNavigation={nav => setNavigation(nav)} />}
    </>
  )
}