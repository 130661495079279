/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { OrderItem } from './OrderItem'
import { makeStyles } from '@material-ui/core/styles'
import { ApiClient } from '../../../Utils/Api'
import Button from '@material-ui/core/Button'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

const useStyles = makeStyles((theme) => ({
  root: {

  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  }
}))

export const OrderList = () => {
  const [orders, setOrders] = useState([])
  const [page, setPage] = useState(1)
  const [showMore, setShowMore] = useState(false)
  const classes = useStyles()

  useEffect(() => {
    loadOrderHistory()
  }, [])

  useEffect(() => {
    loadOrderHistory(true)
  }, [page])

  async function loadOrderHistory(more = false) {
    const { data } = await ApiClient.get(`/customer/orders?pageSize=5&page=${page}`)
    if (more) setOrders([...orders, ...data.docs])
    else setOrders(data.docs)
    setShowMore(data.pages > page)
  }

  function handleMorePages() {
    setPage(page + 1)
  }

  return (
    <div className={classes.root}>
      {orders.map((order) => (
        <OrderItem key={order._id} {...order} />
      ))}
      {orders.length === 0 && (
        <ListItem>
          <ListItemText primary="Você ainda não efetuou nenhuma compra" />
        </ListItem>
      )}
      {showMore &&
        <div className={classes.actions}>
          <Button variant="outlined" color="primary" onClick={handleMorePages}>
            Carregar mais
          </Button>
        </div>
      }
    </div>
  )
}