import React from 'react'

export const NotFoundPage = ({
  title = 'Endereço do site é inválido',
  subTitle = 'informe o slug do site'
}) => (
  <div style={{
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    background: '#f2f2f2'
  }}>
    <h2>{title}</h2>
    <small>{subTitle}</small>
  </div>
)