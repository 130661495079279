import React from 'react'
import Container from '@material-ui/core/Container'
import { ForgotPasswordComponent } from '../../../Components/ForgotPassword/'

export const ForgotPassword = ({ changeNavigation }) => {
  function handleBack() {
    changeNavigation('Login')
  }
  return (
    <Container maxWidth="sm" style={{
      height: 'calc(100vh - 80px)',
      marginBottom: '50px',
      display: 'flex'
    }}>
      <ForgotPasswordComponent
        onCancel={handleBack}
        onSuccess={handleBack}
      />
    </Container>
  )
}