import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import {
  List,
  ListItem,
  ListItemText
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {

  }
}))

export const DialogChoiceUserNotLogged = ({
  open = false,
  onClose,
  onSelect,
  text = 'Atualmente você não está logado, para finalizar o pedido precisamos do seu cadastro, você gostaria de fazer o que agora?'
}) => {
  const classes = useStyles()
  return (
    <Dialog
      className={classes.root}
      open={open}
      onClose={onClose}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle>Atenção</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {text}
        </DialogContentText>
        <List>
          <ListItem button onClick={() => onSelect('register')}>
            <ListItemText primary="Me cadastrar" />
          </ListItem>
          <ListItem button onClick={() => onSelect('login')}>
            <ListItemText primary="Efetuar Login" />
          </ListItem>
          <ListItem button onClick={() => onSelect('nothing')}>
            <ListItemText primary="Nada ainda" />
          </ListItem>
        </List>
      </DialogContent>
    </Dialog>
  )
}
